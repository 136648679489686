export class AppAction {
  name: string;
  paramscmd: string;
  notunnel: boolean = false;
  client: {cmd: string[], redir: string, usebasicauth: boolean, extern: string};
  states: string[]; // list of stats such as 'RUNNING' in which the action is valid. null||undefined if its always valid
  postData: any; // if you need to post to the url to authenticate use this
  pre_open_notify: string;
  post_open_notify: string;
}
export class Strudelapp {
  url: string; // A url used to retrieve extra config options. May be null
  batchcmdurl: string = null; // if an app provides a batchcommandurl it overrides the url provieded for the site.
  submitcmd: string = null; // if the app provides the script for submission (scipt should also provide the json schema for submit params)
  name: string;
  startscript: string; // batch script ... should NOT include resource directives
                       // resource directives like #SBATCH belong in the batchinterface
  appactions: AppAction[]; // appactions don't require an instance. i.e. they take place on the login submit node
  instactions: AppAction[]; // instactions apply to a particular instance of an application, eg connecting to a running desktop
  localbind: boolean; // does the application bind to a port on the localhost
                      // interface or on all interfaces. This behaviour determins
                      /// how we create tunnels
  applist: Strudelapp[] = null;
  desc: string = null;
  static getApp(name: string,applist: any): any {


    var idx, idxURI: number;
    var app: any;
    var sapp: Strudelapp;
    app = applist[0];
    let jname = name;
    var uriname;
    try {
      uriname = decodeURIComponent(name);
    } catch {
      uriname = name;
    }
    for ( let item of applist) {
      sapp = <Strudelapp>item;
      let sname = sapp.name;
      if (sname === jname || sname === uriname) {
        app = item;
        return app;
      }
      if (sapp.applist != null) {
        app = this.getApp(name,sapp.applist);
        if (app != null) {
          return app;
        }
      }
    }
    return null;
  }
}

export class StrudelappInstance {
  port: number;
  password: string;
}
