import { Component } from '@angular/core';
import { TesService } from './tes.service';
import { AuthorisationService} from './authorisation.service';
import { ComputesitesService} from './computesites.service';
import { IpcService } from './ipc.service';
import {BehaviorSubject} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Computesite, Health } from './computesite';
import {SettingsService } from './settings.service';
import {BrowserWindowService} from './browser-window.service';
import {OverlayContainer} from '@angular/cdk/overlay';
import {NotificationsService} from './notifications.service';
import {ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import {filter, map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { environment } from '../environments/environment';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Strudelv2';
  private snackBarRef: any;
  private currentroute: string = '';
  public environment: any;


  constructor(private tesService: TesService,
              public authService: AuthorisationService,
              private computeSitesService: ComputesitesService,
              public settingsService: SettingsService,
              private browserWindowService: BrowserWindowService,
              public snackBar: MatSnackBar,
              private overlayContainer: OverlayContainer,
              private route: ActivatedRoute,
              private router: Router,
              private notifications: NotificationsService,
              private ipcService: IpcService
             ) {



   };

  ngOnInit() {
    const isdefined = filter(v  => v !== null && v !== undefined)
    this.notifications.getMsg().subscribe(msg => this.displayMessage(msg));
    this.settingsService.theme$.subscribe((v) => this.setTheme(v));
    this.settingsService.getTheme();
/*     this.authService.loggedInAuthZ$.pipe(
      isdefined
    ).subscribe((v) => { this.loggedin = (<[]>v).length } ); */
/*     this.authService.loggedOutAuthZ$.pipe(
      isdefined
    ).subscribe((v) => { this.loggedout = (<[]>v).length } ); */
    console.log('call ipcService once for oauth2-redirect');
    this.ipcService.register_oauth2_redirect((url) => { this.route_to_keygen(url)} );
    this.environment = environment;
  }

  route_to_keygen(url: string) {
    //console.log('calling back from oauth', args);
    //var url: string = args[0][0][0];
    console.log('url is', url);
    let fragment = "#" + url.split('#')[1];
    console.log('app.component is updating fragment',fragment);
    this.authService.updateFragment(fragment);
    this.router.navigate(['/sshauthz_callback']);
  }


  toggleMenu() {
    if (this.settingsService.menuToggle$.value == true) {
      this.settingsService.menuToggle$.next(false);
      return
    }
    this.settingsService.menuToggle$.next(true);
  }


  private displayMessage(notification: [string,any]) {
   var msg: string = notification[0]
   var fn: any = notification[1]
   if (msg === null || msg == "") {
     if (this.snackBarRef != undefined) {
       this.snackBarRef.dismiss()
     }
   } else {
     this.snackBarRef = this.snackBar.open(msg,'Dismiss');
     if (fn !== null && fn !== undefined) {
       console.log('adding a subscription',fn);
       this.snackBarRef.afterDismissed().subscribe(() => { console.log(fn); fn() });
     }
   }
 }

  setTheme(theme: string)  {
    console.log('applying theme in app.component',theme);
    if (theme == null) {
      theme = environment.default_theme;
    }
    let container = document.querySelector('app-root');
    let classList = container.classList;
    var c;
    for (c of classList.value.split(' ')) {
      if (c.indexOf('strudel-') == 0) {
        classList.remove(c);
      }
    }
    classList.add(theme);
  }

}
