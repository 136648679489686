import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Subscription, combineLatest} from 'rxjs';
import {filter} from 'rxjs/operators';

import {AuthorisationService } from '../authorisation.service';
import { SshAuthzServer } from '../identity';

@Component({
  selector: 'app-noaccount',
  templateUrl: './noaccount.component.html',
  styleUrls: ['./noaccount.component.css']
})
export class NoaccountComponent implements OnInit {

  public site: SshAuthzServer;
  private subscription: Subscription;

  constructor(private route: ActivatedRoute, private authService: AuthorisationService) { 
      this.site = null;
  }

  ngOnInit() {
    this.subscription = combineLatest([ this.route.paramMap.pipe(filter((v) => v !== null)), this.authService.sshAuthzServers$.pipe(filter((v) => v !== null))])
                            .subscribe(([m,s]) => this.setSite(m,s));
  }
  

  setSite(paramMap: ParamMap, sites: SshAuthzServer[]) {
    if (paramMap.get('site') === null) {
      return;
    }
    for ( let s of sites ) {
      if (s.name == paramMap.get('site') ) {
        this.site = s
      }
    } 
  }

}
