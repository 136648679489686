<div  fxLayout="column" fxLayoutAlign="start none" style="width: 100%" class="strudel-accountinfo-typography">
    <div *ngIf="identity !== null && identity !== undefined"> 
            <div *ngFor="let h of (identity.systemalerts | async)">
                <div *ngIf="h.stat === 'error'">
                    <div class='health-error' style="padding: 20px">
                        {{ h.msg }}
                    </div>
                </div>
            </div>
        <!--</div>-->
        
        <div *ngIf="(identity.accountalerts | async)  ; else loadingaa">
            <mat-list>
                <div *ngFor="let h of identity.accountalerts.value">
                    <div *ngIf="h.type === undefined || h.type != 'quota'">
                        <mat-list-item *ngIf="h.msg !== undefined">
                            <div [ngClass]="cssClass(h)">
                                {{ h.msg }}
                            </div>
                        </mat-list-item>
                    </div>
                    <div *ngIf="h.type == 'table'">
                        <mat-panel-title class="table-header"><span class="mat-subheading-1">{{ h.title.toUpperCase() }}</span></mat-panel-title>
                        <table mat-table [dataSource]="h.data.rows" style="width: 100%">
                            <ng-container *ngFor="let c of h.data.cols" matColumnDef="{{c.key}}">
                                <div *ngIf="c.key == 'name'">
                                <th mat-header-cell *matHeaderCellDef style="text-align: left; border-bottom-color: var(--panel-border-color);">{{ c.header }} </th>
                                <td  mat-cell *matCellDef="let row;" style="text-align: left"> {{row[c.key]}}</td>
                                </div>
                                <div *ngIf="c.key != 'name'">
                                <th mat-header-cell *matHeaderCellDef style="text-align: right; border-bottom-color: var(--panel-border-color);">{{ c.header }} </th>
                                <td  mat-cell *matCellDef="let row;" style="text-align: right"> {{row[c.key]}}</td>
                                </div>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="calculateCols(h.data.cols); sticky: true" ></tr>
                            <tr mat-row *matRowDef="let row;  columns: calculateCols(h.data.cols)" [ngClass]="cssClass(row)" ></tr>
                        </table>
                    </div>
                </div>
            </mat-list>
        </div>
        <ng-template #loadingaa>
            <h2> Loading account info...</h2> 
        </ng-template>
    </div>
</div>
