import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatButtonToggleGroup } from '@angular/material/button-toggle';
import { environment } from '../../environments/environment';
import { DomSanitizer} from '@angular/platform-browser';



@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})


export class AboutUsComponent implements OnInit {
  constructor (    private domSanitizer: DomSanitizer,
    ) {}
  public slides: any;
  ngOnInit() {
    if (environment.about_slides !== null ) {
      this.slides = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.about_slides);
    } else {
      this.slides = null;
    }
    console.log('slides set to',this.slides);

  }

}
