<div fxFlex style="flex: 1 1 0%; box-sizing: border-box">
<div fxLayout="column" fxLayoutAlign="space-around stretch" style="height: 100%; width: 100%" >
    <mat-toolbar color="primary">
      <mat-toolbar-row>
          <!--<button mat-icon-button (click)=idSideNav.toggle()><mat-icon>menu</mat-icon></button>-->

        <span>Strudel v2.0</span>
        <span class="fill-horizontal-space"></span>
      </mat-toolbar-row>
    </mat-toolbar>

<mat-sidenav-container #idSideNav autosize style="height: 100%; width: 100%">
  <mat-sidenav #idSideNav mode="side" opened>
      <app-sv2-side-nav></app-sv2-side-nav>
  </mat-sidenav>

    <div fxLayout="column" fxLayoutAlign="none" style="height: 100%; width: 100%">

    <!-- <div style="height: 600px"></div> -->
      <form>
        <mat-form-field style="width: 100%; height: 100%">
          <textarea fxFlex matInput placeholder="Paste the data sent via email" style="width: 100%; height: 100%" [(ngModel)]="data" name="data"></textarea>
        </mat-form-field>
      </form>

    <div  style="width: 100%" fxLayout="row" fxLayoutAlign="none">
      <div fxFlex></div>
      <button mat-button (click)="onSubmit()">Connect</button>
      <div fxFlex></div>
    </div>
  </div>



    <!-- <mat-card style="width: 100%; box-sizing: border-box">Transfer tasks</mat-card> -->
</mat-sidenav-container>
</div>
</div>
