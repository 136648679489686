import { Component, OnInit } from '@angular/core';
import { Strudelapp } from '../strudelapp';
import { TesService } from '../tes.service';
import { AuthorisationService } from '../authorisation.service';
import { Computesite } from '../computesite';
import { ComputesitesService } from '../computesites.service';
import { Router } from '@angular/router';
import { Job } from '../job';
import { Subscription } from 'rxjs';
import {Identity, KeyCert} from '../identity';


@Component({
  selector: 'app-shareconnect',
  templateUrl: './shareconnect.component.html',
  styleUrls: ['./shareconnect.component.css']
})
export class ShareconnectComponent implements OnInit {

  data: string = "";
  invalid: boolean = false;
  subscription: Subscription;
  constructor(private computesitesService: ComputesitesService,
              private tesService: TesService,
              private authService: AuthorisationService,
              private router: Router) { }

  ngOnInit() {
    setTimeout( () => this.authService.updateAgentContents() )
  }

  connect(jobdata: Job) {
    //this.tesService.connect(jobdata);
  }

  onSubmit() {
    var dataobject: Object;
    var jobdata: Job;
    var site: Computesite;
    var id: Identity;
    try {
      dataobject = JSON.parse(this.data);
      jobdata = <Job>dataobject['job'];
      site = <Computesite>dataobject['site'];
      id = <Identity>dataobject['identity'];
      id.site = site;
      jobdata.identity = new Identity(id.username, site, id.expiry);

      let keycert = dataobject['keycert'];
      if (keycert !== null) {
        this.subscription = this.authService.agentContents$.subscribe(() => this.connect(jobdata))
        //this.authService.sshAdd(keycert);
      } else {
        this.connect(jobdata);
      }
      this.invalid=false;
    } catch {
      if (this.subscription !== undefined) {
        this.subscription.unsubscribe();
      }
      this.invalid=true;
    }
  }

}
