import { Component, EventEmitter, Input, Output } from '@angular/core';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { and, createAjv, isControl, optionIs, rankWith, schemaTypeIs, scopeEndsWith, Tester } from '@jsonforms/core';
import { Observable } from 'rxjs';
import { tap} from 'rxjs/operators';
import { TesService } from '../tes.service';
import { Strudelapp, AppAction } from '../strudelapp';
import { Job } from '../job';
import { BatchInterface } from '../batchinterface';



@Component({
  selector: 'app-launchform',
  templateUrl: './launchform.component.html',
  styleUrls: ['./launchform.component.css']
})
export class LaunchformComponent {
  renderers = [
    ...angularMaterialRenderers,
    
  ];
  @Input() app: Strudelapp;
  @Input() identity;
  
  data: any = {};
  emitData: EventEmitter<any> = new EventEmitter<any>();
  schema: any = {};
  uischema: any = {};

  schemaObs$: Observable<any>;



  uiSchemaObs: Observable<any>;
  dataSchemaObs: Observable<any>;
  //data = dataAsset;
  i18n = {locale: 'en-AU'}
  dateAdapter;
  ajv = createAjv({
    schemaId: 'id',
    allErrors: true
  });

  constructor(private tes: TesService) {

  }

  ngOnInit(): void {
    this.schemaObs$ = this.tes.runCommand(this.identity, this.app.submitcmd, {'input': ""}).pipe(
      tap((v) => console.log(v)),
      tap((v) => { this.schema = v.schema ; this.uischema = v.uischema; this.data = v.data }),
      tap(() => {console.log('data for the form is set to ',this.data)})
    )
  }
  ngOnChanges(): void {
    this.schemaObs$ = this.tes.runCommand(this.identity, this.app.submitcmd, {'input': ""}).pipe(
      tap((v) => console.log(v)),
      tap((v) => { this.schema = v.schema ; this.uischema = v.uischema; this.data = v.data }),
      tap(() => {console.log('data for the form is set to ',this.data)})
    )
  }

  runaction(action: AppAction) {
    var job: Job;
    var newaction: AppAction;
    job =  new Job();
    job.identity = this.identity;
    job.batch_host = 'localhost';
    job.jobid='1';
    newaction = new AppAction();
    newaction.paramscmd = action.paramscmd;
    newaction.notunnel = action.notunnel;
    newaction.client = action.client;
    newaction.postData = action.postData;
    this.tes.connect(job,newaction);
  }

  launch() {
    // tell the batchbuilder iframe that lanch was selected
    // This is the cue to save the values as defaults for next launch

    let bi = new BatchInterface();
    bi.submitcmd = this.app.submitcmd;
    let app = new Strudelapp()
    app =  JSON.parse(JSON.stringify(this.app))
    let submitparams = JSON.parse(JSON.stringify(this.data))
    submitparams['startscript'] = this.app.startscript;
    submitparams['name'] = this.app.name;
    app.startscript = btoa(JSON.stringify(submitparams))

    let appparams = this.data;
    this.tes.submit(app,this.identity,bi);

  }
}
