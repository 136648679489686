import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, concat, forkJoin } from 'rxjs';
import { catchError, map, tap, take,filter,skip, switchMap, shareReplay } from 'rxjs/operators';
import {LocationStrategy, Location} from '@angular/common';
import { Identity, AuthToken, KeyCert, SshAuthzServer } from './identity';
import { APIServer } from './apiserver';
import {BackendSelectionService} from './backend-selection.service';
import { throwError, of, combineLatest, from } from 'rxjs';
import {NotificationsService} from './notifications.service';
import { IpcService } from './ipc.service';
import * as jwktossh from "jwk-to-ssh";



export class SshauthzServer {}

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {
  // public token: BehaviorSubject<AuthToken>;
  //public readonly sshAuthzServers: BehaviorSubject<SshAuthzServer[]>;
  //public readonly agentContents: BehaviorSubject<any>;
  //public loggedInAuthZ: BehaviorSubject<SshAuthzServer[]>;
  //public loggedOutAuthZ: BehaviorSubject<SshAuthzServer[]>;
  public loggedInAuthZ$: Observable<SshAuthzServer[]>;
  public loggedOutAuthZ$: Observable<SshAuthzServer[]>;
  //public progress: Subject<string>;
  // private keyCert: Subject<KeyCert>;
  public backendURI: string;
  public keys: KeyCert[];
  private fragment$: BehaviorSubject<string> = new BehaviorSubject(null);
  public agentContents$: Observable<any>;
  private backendURI$: Observable<string>;
  public loggedin$: BehaviorSubject<number>;
  public loggedout$: BehaviorSubject<number>;
  public sshAuthzServers$: Observable<SshAuthzServer[]>;
  public refresh$: Subject<boolean> = new Subject();
  public reAdd$: Subject<any> = new Subject();


  constructor(private http: HttpClient,
              private locationStrategy: LocationStrategy,
              private router: Router, 
              private backendSelectionService: BackendSelectionService,
              private location: Location, 
              private notifications: NotificationsService,
              private ipcService: IpcService) {
                
                //this.sshAuthzServers = new BehaviorSubject<SshAuthzServer[]>([]);
                //this.loggedInAuthZ = new BehaviorSubject<SshAuthzServer[]>(null);
                //this.loggedOutAuthZ = new BehaviorSubject<SshAuthzServer[]>([]);
                this.backendURI = null;
                //this.agentContents = new BehaviorSubject(null);
                this.keys = [];
                //this.getSshAuthzServers();
                this.keys = [];
               
                this.sshAuthzServers$ = this.getSshAuthzServersObservable();
    this.loggedin$= new BehaviorSubject<number>(null);
    this.loggedout$= new BehaviorSubject<number>(null);
    this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== null && v !== undefined),
    )
      .subscribe((value) => { this.backendURI = value.tes }); // Once we have a value for backend, store that value locally

    // // Once we have backend server, check what our ssh agent has in it
    // this.backendSelectionService.apiserver.pipe(
    //   filter((v) => v !== null && v !== undefined),
    //   tap(() => console.log('backendSelectionService fired causing agent contents to update')),
    //   switchMap((v) => this.updateAgentContents(v.tes)),
    // ).subscribe((_) => { return }, (err) => console.error(err));  // An empty subscription is necessary for the observables to fire

    this.backendURI$ = this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== null && v !== undefined),
      map((v) => (<APIServer>v).tes)
    )

    this.agentContents$ = this.backendURI$.pipe(
      tap((v) => console.log('backendURI changed, causing agent contents to update',v)),
      switchMap((v) => this.updateAgentContents(v)),
      filter((v) => v !== null && v !== undefined),
      shareReplay()
    )


    /* Once we have a value for agent Conents, and a list of servers, we can figure out which ones we hav elogged into and which ones we haven't */

    var authZ$: Observable<any>;
    authZ$ = combineLatest([this.agentContents$,this.sshAuthzServers$.pipe(filter((v) => v !== null && v !== undefined))]).pipe(
      map(([agentContents,authzServers]) => { return this.updateLoggedAuthZ(agentContents,authzServers)}),
      tap((v) => { this.loggedin$.next(v[0].length); this.loggedout$.next(v[1].length)} ),
      catchError((e) => { console.error('errort getting values for logged in and out',e); return throwError(e) }),
      tap((v) => console.log('authZ observable fired')),
      shareReplay()
    );
    //authZ$.subscribe(([loggedin,loggedout]) => { this.loggedInAuthZ.next(loggedin); this.loggedOutAuthZ.next(loggedout);  })
    this.loggedInAuthZ$ = authZ$.pipe(map((v) => v[0]));
    this.loggedOutAuthZ$ = authZ$.pipe(map((v) => v[1]));

    const apiserver$: Observable<APIServer> = this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== undefined),filter((v) => v !== null),
    );
    combineLatest([this.reAdd$.pipe(
      filter((v) => v !== undefined && v !== null)), apiserver$]).subscribe(
      ([id, apiserver]) => { this.addKeysFromStorage(apiserver)})
    
    this.initKeygenPipelines();
 }

 private addKeysFromStorage(apiserver: APIServer) {
  var req: Observable<any>[] = [] 
  var keys: KeyCert[];
  keys = this.getKeys();
  if (keys.length>0) {
    for (let k of this.getKeys()) {
      console.log('addKeysFromStorage',k);
      req.push(this.sshAdd(k,apiserver))
    }
    forkJoin(req).subscribe((v) => { this.refresh$.next(true)})
  } else {
    this.refresh$.next(true);
  }
 }
 
 updateFragment(frag) {
  this.fragment$.next(frag);
 }

 storeLocalAuthZ(authz: any) {
   try {
     localStorage.setItem('localauthservers',JSON.stringify(authz));
   } catch {
   }
   //this.getSshAuthzServers();
 }

 removeLocalAuthZ() {
   localStorage.removeItem('localauthservers');
   //this.getSshAuthzServers();
 }

/*  getSshAuthzServers() {
   let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: false};
   this.http.get<SshAuthzServer[]>('./assets/config/authservers.json',options)
                    .pipe(catchError(this.handleError('getSshAuthzServers')))
                    .subscribe(resp => this.updateSshAuthzServers(resp));
 } */
 getSshAuthzServersObservable(): Observable<SshAuthzServer[]> {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: false};
  return this.http.get<SshAuthzServer[]>('./assets/config/authservers.json',options)
                   .pipe(catchError(this.handleError('getSshAuthzServers')),
                   map((v) => this.concatLocalAuthZ(<SshAuthzServer[]>v)))
}

 private concatLocalAuthZ(v:SshAuthzServer[]): SshAuthzServer[] {
  var localauths: SshAuthzServer[];
  try {
    localauths = JSON.parse(localStorage.getItem('localauthservers'))
  } catch {
    localauths = []
  }
  if (localauths === null) {
    localauths = [];
  }
  for (let server of localauths){ 
    v.push(server);
  }
  return v;
 }

 private updateLoggedAuthZ(agentContents,authzServers) {
     let loggedin = []
     let loggedout = []
     var found: boolean;
     if (agentContents == null) {
       return
     }
     for (let s of authzServers) {
         found=false;
         for (let cert of agentContents) {
           if ('Signing CA' in cert) {
             for (let ca of cert['Signing CA']) {
               if (ca.indexOf(s.cafingerprint) != -1) {
                 loggedin.push(s)
                 found=true;
                 continue;
               }
             }
           }
           if (found) {
               continue;
           }
         }
         if (!found)  {
           loggedout.push(s)
         }
     }
     return [loggedin, loggedout]
 }
        

 updateSshAuthzServers(resp) {
   var auths: SshAuthzServer[];
   var localauths: SshAuthzServer[] = [];
   var server: SshAuthzServer;
   auths = <SshAuthzServer[]>resp;
   try {
       localauths = JSON.parse(localStorage.getItem('localauthservers'))
   } catch {
       localauths = []
   }
   if (localauths !== null) {
       for (server of localauths) {
           auths.push(server);
       }
   }
   //this.sshAuthzServers.next(auths);
 }

public getKeys(id?: Identity): KeyCert[] {
  try{
    return JSON.parse(sessionStorage.getItem('keys'));
  } catch {
    return [];
  }
}

    /* makeKeyCert(key: string, resp, sshauthzservice: SshAuthzServer) {
   let keyCert = new KeyCert()
   keyCert.key = key;
   keyCert.cert = resp['cert'];
   var keys: KeyCert[] = [];
   try{
     keys = JSON.parse(sessionStorage.getItem('keys'));
   } catch {
     keys = [];
   }
   if (keys === null) {
     keys = [];
   }
   keys.push(keyCert);
   sessionStorage.setItem('keys',JSON.stringify(keys))
   // this.tesService.keyCert.next(keyCert);
   // As soon as the certificate has been generated, we log back out of the signing server
   if (!(sshauthzservice.logout === null)) {
     window.open(sshauthzservice.logout);
   }
   let path=sessionStorage.getItem('path');
   //skip1 because loggedInAuthZ is a behaviour subject and we don't want the current value but the value
   this.loggedInAuthZ.pipe(skip(1),take(1)).subscribe( () => {this.readyToNavigate.next([true,path])});
   this.sshAdd(keyCert);
   // only navigate once the agent contents has been refreshed
 }*/

 public querySshAgentError(error: any) {
   //this.agentContents.next([]);
   if (error.status == 0) {
     this.notifications.notify("A network error occured. Are you connected to the internet?")
   }
   this.notifications.notify("Error querying ssh agent");
 }

  public updateAgentContents(apiserver?: string): Observable<any> {
    /* Query ssh agent running on the apiserver 
     * Tap the even stream to update the notifications 
     */

    if (this.ipcService.useIpc) {
      return this.ipcService.sshAgent(null);
    } else {

   if (apiserver === undefined) {
     if (this.backendURI == null) {
       throwError('no backend to query');
     } else {
       apiserver = this.backendURI
     }
   }

   let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   var anyvar: any;
   let agentquery$ = this.http.get<any>(apiserver+'/sshagent',options)
   let agentpipe$ = agentquery$.pipe(
     catchError((e) => { this.querySshAgentError(e); return of([])}),
     switchMap((resp) => of(this.addExpiryField(resp))),
     tap((resp) => { 
      //  if (this.agentContents.value !== null && this.agentContents.value.length > resp.length) {
      //    this.notifications.notify("Your login expired. Please login again");
      //  } else {
      //    this.notifications.notify("");
      //  };
      this.notifications.notify("");
      //this.agentContents.next(resp)
     }),
     catchError((e) => { console.error('updateAgentContents error',e) ; return of([])}),
     //tap((_) => this.notifications.notify(""))
     tap((v)=>console.log('agent contents',v))
   )
   return agentpipe$
 }
}

 private addExpiryField(resp): any[] {
  var res: any[]
  res = []
  for (let id of resp) {
    var validstr: String;
    validstr = id.Valid[0];
    id.expiry = Date.parse(validstr.split(" ")[3]+"Z")
    res.push(id);
  }
  return res
 }

 private killAgent() {
   this.notifications.notify("Logging out")
   let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   var anyvar: any;
   return this.http.delete<any>(this.backendURI+'/sshagent',options)
     .pipe(
       catchError(this.handleError(anyvar)),
       switchMap((v) => this.updateAgentContents()))
    
 }

 public logout(): Observable<any> {
   sessionStorage.removeItem('keys');
   return this.killAgent();
 }

 public login(authservice: SshAuthzServer) {
   let redirect_uri = window.location.origin+this.locationStrategy.getBaseHref()+"sshauthz_callback";
   if (redirect_uri.includes("file:///")) {
     redirect_uri = "http://localhost:4200/sshauthz_callback";
   }
   let nonce=Math.random().toString(36).substring(2, 15)

   sessionStorage.setItem('authservice', JSON.stringify([authservice,nonce]));
   sessionStorage.setItem('path', '/launch');
   if (authservice.scope == null) {
     window.location.assign(authservice.authorise+"?response_type=token&redirect_uri="+redirect_uri+"&state="+nonce+"&client_id="+authservice.client_id);
   } else {
     window.location.assign(authservice.authorise+"?response_type=token&redirect_uri="+redirect_uri+"&state="+nonce+"&client_id="+authservice.client_id+"&scope="+authservice.scope);
   }
 }

 private httperror(error: any) {
   this.notifications.notify('There was an error logging in or generating crypto tokens');
   console.error(error);
 }

 private handleError<T> (result?: T) {
   return (error: any): Observable<T> => {
     if (error.status == 500) {
       return throwError("The backend server encountered and error. Please try again in a few minutes")
     }
     return throwError(error.message);
     // return of(result as T);
   };
 }

 initKeygenPipelines() {

    const token$: Observable<AuthToken> = this.fragment$.pipe(
      filter((v) => v !== null),
      map((v) => this.extractToken(v)),
    );
    const key$ = from(window.crypto.subtle.generateKey(
      {
        name: "ECDSA",
        namedCurve: "P-256",
      },
      true,
      ["sign","verify"]
    )).pipe(
      switchMap((v) => { return combineLatest([from(window.crypto.subtle.exportKey("jwk",v.privateKey)),from(window.crypto.subtle.exportKey("jwk",v.publicKey))]) }),
      map(([key,pub]) => {
        return {'private': jwktossh.pack({'jwk': key, 'comment': '', 'public': false})+"\n", 'public': jwktossh.pack({'jwk': pub, 'comment': '', 'public': true})+"\n"};
      }),
    )
    const apiserver$: Observable<APIServer> = this.backendSelectionService.apiserver.pipe(
      filter((v) => v !== undefined),filter((v) => v !== null),
    );

    let keycert$ = combineLatest([token$, key$, apiserver$]).pipe(
      switchMap(([token,key,apiserver]) => this.getCert(token,key,apiserver),
        ([token,key,apiserver],cert) => [key,cert,token]),
      tap(([key,cert,token]) => this.logout_sshauthz(token.sshauthzservice)),
    );

    let agent$ = combineLatest([keycert$.pipe(filter((v) => v !== null)),apiserver$]).pipe(
      switchMap(([keycert,apiserver]) => this.addCert(keycert,apiserver)),
      tap((v)=>console.log('posted cert to agent',v)),
      switchMap((_) => this.updateAgentContents()),
      //switchMap((_) => this.loggedInAuthZ),
      switchMap((_) => of([null])),
    );
    agent$.subscribe( (res) => this.router.navigate([sessionStorage.getItem('path')]),
                      (err) => { console.error(err) ; 
                                 if (err.sshauthzservice !== undefined ) {
                                    this.logout_sshauthz(err.sshauthzservice)
                                    this.router.navigate(['/noaccount',err.sshauthzservice.name])
                                 } else {
                                    this.router.navigate(['/login'])}
                                 } )
  }

 extractToken(frag: string) {
   if (frag === undefined || frag == null) {
       return;
   }
   let tokenmatch = null;
   let statematch = null;
   if (!(frag === undefined) && !(frag == null)) {
     tokenmatch = frag.match(/access_token\=([^&]+)(&|$)/);
     statematch = frag.match(/state\=([^&]+)(&|$)/);
   }
   if (tokenmatch == null || statematch == null) {
     throw new Error('no token present');
   }

   let accesstoken = tokenmatch[1];
   let state = statematch[1];
   let tuple = JSON.parse(sessionStorage.getItem('authservice'));
   if (tuple[1] != state) {
     throw new Error('callback state parameter does not match'+frag+tuple);
   }

   return new AuthToken(tokenmatch[1],tuple[0]);

 }

  logout_sshauthz(sshauthzserver) {
   if (sshauthzserver !== undefined && sshauthzserver.logout !== null) {
     window.open(sshauthzserver.logout);
   }
  }

  getCert(token: AuthToken, key: any, apiserver: APIServer): Observable<any> {
    let headers = new HttpHeaders({'Authorization':'Bearer '+token.token});
    let options = { headers: headers, withCredentials: false};
    var now = new Date()
    var end = new Date(now.getTime() + 28*24*60*60*1000); //request a certificate valid for 28 days
                                                          //its expected that the user will terminate the session by closing their browser/sleeping their laptop before this
    //var end = new Date(now.getTime() + 30*1000); // Uncomment if you want to test certificates expiring
    let data = {'public_key': key.public, 'end': end.toISOString()};
    return this.http.post<any>(token.sshauthzservice.sign,data, options).pipe(
      map((v) => v.certificate),
      tap((v)=> console.log('got cert',v)),
      catchError((e) => { console.error(e); return throwError(token) })
    )
  }

  addCert(kclist: any, apiserver: APIServer): Observable<any> {
    let keyCert = new KeyCert()
    keyCert.key = kclist[0].private
    keyCert.cert = kclist[1]
    return this.sshAdd(keyCert,apiserver);
  }

 public sshAdd(keyCert: KeyCert, apiserver): Observable<any> {
  this.storeKey(keyCert); 
  let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   let data = {'key': keyCert.key, 'cert': keyCert.cert};
    if (this.ipcService.useIpc) {
      return this.ipcService.addCert(data);
    } else {
      return this.http.post<any>(apiserver.tes+'/sshagent',data,options);
    }
 }

  storeKey(keyCert: KeyCert) {
    var keys: KeyCert[] = [];
    try{
      keys = JSON.parse(sessionStorage.getItem('keys'));
    } catch {
      keys = [];
    }
    if (keys === null) {
      keys = [];
    }
    keys.push(keyCert);
    sessionStorage.setItem('keys',JSON.stringify(keys))
  }



  
}
