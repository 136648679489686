<div *ngIf="schemaObs$ | async as schemaQuery ; else loading" style="display:flex; flex-direction: row; width: 100%">

    <div style="flex: 1 1 auto">
<jsonforms
    [(data)]="data"
    [schema]="schema"
    [uischema]="uischema"
    [renderers]="renderers"
    [i18n]="i18n"
    [ajv]="ajv"
></jsonforms>
</div>

<div style="flex: 1 1 auto">

<mat-list>
    <mat-list-item *ngIf="app.startscript !== null"><button mat-flat-button (click)="launch()" color="primary" #launchbtn [disabled]="!ajv.validate(schema,data)">Launch</button></mat-list-item>
    <mat-list-item *ngFor="let action of app.appactions"><button mat-flat-button (click)="runaction(action)" color="primary"  >{{ action.name }}</button></mat-list-item>
</mat-list>
</div>

</div>

<ng-template #loading>
    <div> Loading ...</div>
</ng-template>
