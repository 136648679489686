<div class="other-component" fxLayout="row" fxLayoutAlign="space-around">
<div fxLayout="column" class="content" style="width: 800px">
    <div class="heading"><h1>Settings</h1></div>
    <mat-divider></mat-divider>
    <div class="heading"><h2>Appearance</h2></div>
    <div style="width: 100%">
        <mat-form-field style="width: 100%" >
            <mat-label>Theme</mat-label>
            <mat-select [(value)] ="theme" (selectionChange)="selectTheme($event.value)">
                <mat-option *ngFor="let opttheme of themes" [value]="opttheme">
                    {{ opttheme.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div>
        <div >
            <mat-slide-toggle color=primary [checked]="settingsService.appdesc" (change)="settingsService.setAppDesc($event)">Show Appliction Descriptions</mat-slide-toggle>
        </div>
        <div>
        <mat-slide-toggle color=primary [checked]="settingsService.logging" (change)="settingsService.setLogging($event)">Allow usage logs to be collected</mat-slide-toggle>
        </div>
        <div>
        <mat-slide-toggle color=primary [checked]="settingsService.warndialog" (change)="settingsService.setWarnDialog($event)">Warn every time you go to open a second app</mat-slide-toggle>
        </div>


    <div class="heading"><h2>Advanced</h2></div>
    <div>
        <div *ngIf="(backendSelectionService.apiservers | async) !== undefined">
        <mat-form-field style="width: 100%">
            <mat-label>API Server</mat-label>
            <mat-select [ngModel]="selectedApiServer" (selectionChange)="backendSelectionService.setApiServer($event.value)">
                <mat-option *ngFor="let apis of backendSelectionService.apiservers|async" [value]="apis" [disabled] ="apis.ping === undefined || apis.ping === null">
                    {{ apis.name }} <span *ngIf="apis.ping !== undefined">(ping: {{ apis.ping }} ms)</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around" style="width: 100%">
        <div> 
            <button type="button" mat-flat-button color=primary (click)="fileInput.click()">Load Config</button>
            <input hidden (change)="loadConfig($event)" accept=".json" #fileInput type="file" id="file">
        </div>
        <div>
            <button mat-flat-button color=primary (click)=resetConfig()>Reset Config</button>
        </div>
    </div>
</div>
</div>
