<!--<div *ngIf="(identitySubject | async) === null" >
   Click login and select a provider or select one you've already logged in to.
</div>-->
<div *ngIf="identity !== null" style="width: 100%" >
    <!--<mat-accordion style="width: 100%" [displayMode]="flat" [togglePosition]="'after'">-->
    <mat-accordion style="width: 100%" [displayMode]="'flat'">
        <mat-expansion-panel style="width: 100%" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> 
                    PENDING/RUNNING {{ getAppName() }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item>
                    <div  fxLayout="row" fxLayoutAlign="space-between" style="width: 100%">
                        <div fxFlex="10%"> State </div>
                        <div fxFlex="10%"> JobID </div>
                        <div fxFlex="10%"> Host </div>
                        <div fxFlex="20%" fxLayout="column"> Resources </div>
                        <div fxFlex="20%" fxLayout="column"> Time </div>
                        <div fxFlex></div>
                        <div fxFlex="15%" fxLayout="row"></div>
                    </div>
                </mat-list-item>
            <mat-divider style="background-color: var(--panel-border-color)"></mat-divider>
            <div *ngFor="let job of joblist ; let lastItem = last">
                <div *ngIf="job.app !== null && app.name == job.app.name && job.state != 'Finished'">
                    <app-job [jobdata]=job [updating]='true'></app-job>
                    <mat-divider></mat-divider>
                </div>
            </div>
        </mat-list>
        <!--<button mat-button (click)="refreshJobs()" style="width: 100%; text-align: right">Refresh Job list</button>-->
        </mat-expansion-panel>
    </mat-accordion>
    <div>&nbsp;</div>
    <!--<mat-accordion style="width: 100%" [displayMode]="flat" [togglePosition]="'after'">-->
    <mat-accordion style="width: 100%" [displayMode]="'flat'">
        <mat-expansion-panel style="width: 100%" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title> 
                    <span>COMPLETED {{ getAppName() }}</span>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item>
                    <div  fxLayout="row" fxLayoutAlign="space-between" style="width: 100%">
                        <div fxFlex="10%"> State </div>
                        <div fxFlex="10%"> JobID </div>
                        <div fxFlex="10%"> Host </div>
                        <div fxFlex="20%" fxLayout="column"> Resources </div>
                        <div fxFlex></div>
                        <div fxFlex="15%" fxLayout="row"></div>
                    </div>
                </mat-list-item>
            <mat-divider style="background-color: var(--panel-border-color)"></mat-divider>
            <div *ngFor="let job of joblist ; let lastItem = last">
                <div *ngIf="job.app !== null && app.name == job.app.name && job.state == 'Finished'">
                    <app-job [jobdata]=job [updating]="false"></app-job>
                    <mat-divider></mat-divider>
                </div>
            </div>
        </mat-list>
        <!--<button mat-button (click)="refreshJobs()" style="width: 100%; text-align: right">Refresh Job list</button>-->
        </mat-expansion-panel>
    </mat-accordion>
</div>
