
<div fxLayout="column" fxLayoutAlign="flex-start stretch" style="height: 100%; width: 100%" >

    <!--<div fxLayout="row" fxLayout="space-around stretch"  fxLayoutGap="1%" style="height: 90%">-->
    <!--<div fxLayout="row" fxLayout="space-around stretch" fxLayoutGap="1%"  style="height: 90%">-->
    <div fxLayout="row" fxLayout="space-around stretch" fxLayoutGap="1%"  style="height: 75%">
      - <div fxFlex *ngFor="let idx of [0,1]" style="height: 100%;"> 
        <!--  <div *ngFor="let idx of [0,1]" style="height: 100% " fxFlex>-->
        <!--<div fxLayout="column" fxLayoutAlign="none stretch" style="width: 100%; height: 100%" >-->
        <!--<div style="width: 100%; height: 100%" >-->
                <mat-form-field style="width:100%">
                    <mat-select (selectionChange)="setId(idx,$event)" placeholder="Choose a server">
                      <mat-option *ngFor="let s of computesitesService.ftidentities | async" [value]="s">
                        {{ s.username }}@{{s.site.name }}
                      </mat-option>
                    </mat-select>
                 </mat-form-field>
                 <span class="mat-body"> {{ path[idx] }}</span>
                 <div *ngIf="working[idx] | async">
                    <mat-spinner></mat-spinner>
                 </div>
                 <!--<div *ngIf="!(working[idx] | async)" style="height: 100%; overflow-y: auto">-->
                 <div *ngIf="!(working[idx] | async)" style="height: 100%">
                    <div  style="height: 100%; width: 100%; overflow-y: auto">
                        <file-explorer [fileElements]="fileElements[idx] | async"
                                     [path]="pathSubject[idx] | async"
                                     (navigatedDown)="navigateToFolder(idx,$event)"
                                     (navigatedUp)="navigateUp(idx,$event)"
                                     [canNavigateUp]="canNavigateUp[idx] | async"
                                     (sendFile)="queueFile(idx,$event)"
                                     (folderAdded)="newDirectory(idx,$event)">
                        </file-explorer>
                    </div>
                 </div>
                 <!--</div>-->
        </div>
    </div>
    <div style="height: 25%; overflow-y: auto">
        <h3>Transfer list</h3>
        <mat-list>
            <div *ngFor="let t of transferlist">
                <mat-list-item>
                  {{ t.srcpath }} {{ t.dstpath }}
                </mat-list-item>
            </div>
        </mat-list>
    </div>
</div>
