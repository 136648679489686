import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

import { AuthorisationService } from '../authorisation.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private authService: AuthorisationService,
    private router: Router,
  ) {
 }

  ngOnInit() {
  }

  logout() {
    this.authService.logout().subscribe(() => this.router.navigate(['/login']));
    //this.router.navigate(['/login']);
  }

}
