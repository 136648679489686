<div  fxFlex style="flex: 1 1 0%; box-sizing: border-box">
<mat-sidenav-container fxFlexFill autosize>
    <mat-sidenav #idSideNav mode="side" [opened]="(settingsService.menuToggle$ | async) && (settingsService.useMenu$ | async)" class="strudel-service-typography">
        <div fxLayout="column" fxLayout="stretch" style="height: 100%">
            <nav class="sidenav-box">
            <mat-list class="sidenav-box" style="border-style: none">
                <mat-list-item>
                    <span class="sidenav-header"><h2 style="margin: 0px; text-align: left">Select a site</h2></span>
                </mat-list-item>
            </mat-list>
            <div style="border-style: solid none none none ; border-width: 1px; border-color: var(--panel-border-color); box-sizing: border-box">
            <div *ngIf="computeSitesService.appidentities$ | async as obs; else loading">
            <mat-accordion style="width: 100%" [displayMode]="'flat'">
                <div *ngFor="let id of appidentities" style="border-style: none none solid none; border-width: 1px; border-color: var(--panel-border-color) ">
                    <!--<mat-expansion-panel (afterExpand)="selectId(id)" (closed)="selectId(id)" style="width: 100%">-->
                    <mat-expansion-panel style="width: 100%" [expanded]="identity === id" class="mat-elevation-z0">
                        <mat-expansion-panel-header class="small-headers">
                            <mat-panel-title >
                                <span fxFlex matBadge="{{ countErrors((id.systemalerts | async), (id.accountalerts | async)) }}" 
                                                        [matBadgeHidden]= "countErrors((id.systemalerts | async), (id.accountalerts | async)) == 0"  
                                                        matBadgePosition="above before" 
                                                        matBadgeColor="warn" 
                                                        matBadgeOverlap="false" matBadgeSize="small"
                                                        style="text-align: left" 
                                                        class="sidenav-site-header small-headers">
                                    {{ id.displayName() }}
                                </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        
                        <mat-list style="width: 100%">
                            <mat-list-item>
                                <button mat-button  style="width: 100%; text-align: left"
                                   [routerLink]="['/launch',id.displayName(),'accountinfo']"
                                   routerLinkActive="linkActive" #rla="routerLinkActive">
                                    Account Info
                                </button>
                        </mat-list-item>
                        
                        <app-strudelapplist [applist]=id.appCatalogStatic [identity]="id" (appChange)="selectApp($event)" style="width: 100%"></app-strudelapplist>
                        </mat-list>
                    </mat-expansion-panel>
                    <!--<div style="width: 100%; border-bottom: 1px solid var(--panel-border-color);"></div>-->
                </div>
            </mat-accordion>
            </div><ng-template #loading>Loading...</ng-template>
            </div>
            </nav>
        </div>
        <div fxFlex></div>
    </mat-sidenav>
    <mat-sidenav-content>
            <div *ngIf="computeSitesService.appidentities$ | async as obs; else loading">
        <div fxLayout="column" fxLayoutAlign="start none" style="height: 100%">
            <div *ngIf="(app$ | async) == null" style="padding-left: 5%; padding-right: 5%;">
                <app-accountinfo [identity]="identity"></app-accountinfo>
            </div>
            <div *ngIf="identity !== undefined &&  identity !== null && app$.value !== undefined && app$.value !== null" style="min-width: 800px; padding-left: 5%; padding-right: 5%;">
                <app-launch-dialog [identity]="identity" [appSubject]="app$"></app-launch-dialog>
                <app-joblist [identity]="identity" [app]="(app$ | async)"></app-joblist>
            </div>
            

        </div>
        </div><ng-template #loading>Determining which sites you are logged into already ...</ng-template>
    </mat-sidenav-content>
</mat-sidenav-container>
</div>
