import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';

import { AuthorisationService } from '../authorisation.service';
import { LogoutdialogComponent } from '../logoutdialog/logoutdialog.component';


@Component({
  selector: 'app-sv2-side-nav',
  templateUrl: './sv2-side-nav.component.html',
  styleUrls: ['./sv2-side-nav.component.css']
})
export class Sv2SideNavComponent implements OnInit {

  constructor(public authService: AuthorisationService,  public dialog: MatDialog,) { }

  ngOnInit() {
  }
  logout() {
      let dialogRef = this.dialog.open(LogoutdialogComponent, {
        width: '250px',
        height: '400px',
      });
  }

  login (sshauthzserver) {
    this.authService.login(sshauthzserver);
  }

}
