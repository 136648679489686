<form [formGroup]="contactForm">
<div class="other-component" fxLayout="row" fxLayoutAlign="space-around">    
<div fxLayout="column" class="content">
    <div class=heading><h1>CONTACT US</h1></div>
    <p>
    STRUDEL is used across a number of locations. 
    To help us better direct your request, please select from a list of people
    who contribute to keeping STRUDEL functioning.  If you don't know how the best person to contact is just select everyone
    </p>
    <div class=heading><h2>Who would you like to contact?</h2></div>

    <div>
        <mat-select [compareWith]="compareFn" (selectionChange)="selectionChanged($event)" placeholder="Select a receipient" >
           <mat-option *ngFor="let contact of (contacts$ | async)" [value]="contact">
                 {{ contact }}
           </mat-option>
    </mat-select>
    </div>
    <mat-divider></mat-divider>
    <div style="width: 100%">
    <mat-chip-list #chipList aria-label="Send mail to" style="width: 100%">
        <mat-chip *ngFor="let c of (tofield$ | async)" [selectable]=false [removable]=true (removed)="remove(c)">{{c}}<mat-icon matChipRemove>cancel</mat-icon></mat-chip>
    </mat-chip-list>
    </div>
    <mat-divider></mat-divider>
    <mat-form-field>
        <input type="text" #namefield formControlName="name" matInput placeholder="Name">
    </mat-form-field>
    <mat-form-field >
        <input type="text" formControlName="email" matInput placeholder="Email">
    </mat-form-field>
    <mat-form-field >
        <input type="text" matInput formControlName="subject" placeholder="Subject">
    </mat-form-field>
    <mat-form-field >
        <textarea matInput formControlName="message" matTextareaAutosize matAutosizeMinRows=10 matAutosizeMaxRows=10 placeholder="Type your message here..."></textarea>
    </mat-form-field>
    <div fxLayoutAlign="end none">
        <button mat-flat-button color=primary class="big-button" (click)="sendmail()">Submit</button>
    </div>
</div>
</div>
    </form>
