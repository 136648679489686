import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import {OverlayContainer} from '@angular/cdk/overlay';
import { Subscription } from 'rxjs';
import { tap, filter, map } from 'rxjs/operators';

import { Router, NavigationStart } from '@angular/router';

import { TesService } from '../tes.service';
import {BackendSelectionService } from '../backend-selection.service';
import { AuthorisationService } from '../authorisation.service';
import { Identity } from '../identity';
import { Computesite } from '../computesite';
import { LogoutdialogComponent } from '../logoutdialog/logoutdialog.component';
import { SshAuthzServer } from '../identity';
import { ComputesitesService } from '../computesites.service';
import { IpcService } from '../ipc.service';

import {DomSanitizer} from '@angular/platform-browser';
import {PipeTransform, Pipe} from "@angular/core";
import { ThisReceiver } from '@angular/compiler';

@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public authorised: boolean;
  public identity: Identity;
  public identities: Identity[];
  public selectedApiServer: any;
  public selected: any;
  private subscriptions: Subscription[];
  public loggedInAuthZ: any;
  public loggedOutAuthZ: any;


  constructor( public dialog: MatDialog,
                public tesService: TesService,
                public backendSelectionService: BackendSelectionService,
                public authService: AuthorisationService,
                public computeSitesService: ComputesitesService,
                public overlayContainer: OverlayContainer,
                private router: Router,
                private ipcService: IpcService,
                ) {



  }
    
/*   updateSshAuthZServers(o) {
    this.sshauthzservers = o;
  } */

  ngOnInit() {
    //this.subscriptions.push(this.computeSitesService.appidentities.subscribe(o => this.navLaunch(o)));
/*     let navlaunch = localStorage.getItem('strudel-navlaunch')
    //const isdefined = filter(v  => v !== null && v !== undefined)

    if (navlaunch == 'true') {
      localStorage.setItem('strudel-navlaunch',null)
      //this.subscriptions.push(this.computeSitesService.appidentities.subscribe(o => this.navLaunch(o)));
    }
 */

    this.authService.loggedInAuthZ$.subscribe((v) => {console.log('loggedin',v); this.loggedInAuthZ = v})
    this.authService.loggedOutAuthZ$.subscribe((v) => { console.log('loggedout',v); this.loggedOutAuthZ = v})

  }

  ngOnDestroy() {

  }

/*   navLaunch(o) {
    this.computeSitesService.appidentities$.pipe(filter((v) => v.length >= 0)).subscribe((v) => this.router.navigate['/launch'])
     if (this.computeSitesService.appidentities.value !== null && this.computeSitesService.appidentities.value.length > 0) {
      this.router.navigate(['/launch']);
    } 
  }
 */

  login () {
    //localStorage.setItem('strudel-navlaunch','true');
    this.authService.login(this.selected);
  }
}

