import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import { LauncherComponent } from './launcher/launcher.component';
import { JoblistComponent } from './joblist/joblist.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDialogModule, MatDialog } from '@angular/material/dialog';
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ComputesitesService } from './computesites.service';
import { AuthorisationService } from './authorisation.service';
import { JobsService } from './jobs.service';
//import { FileExplorerModule } from './file-explorer/file-explorer.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';


import { TesService} from './tes.service';
import { BrowserWindowService } from './browser-window.service';
import { BackendSelectionService} from './backend-selection.service';
import { SubmitAppService } from './submit-app.service';
import { IpcService } from './ipc.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { JobComponent } from './job/job.component';
// import { SiteselectionComponent } from './siteselection/siteselection.component';
// import { TeserrorsComponent } from './teserrors/teserrors.component';
import { AppRoutingModule } from './/app-routing.module';
// import { LogindialogComponent } from './logindialog/logindialog.component';
import { LogoutdialogComponent } from './logoutdialog/logoutdialog.component';
// import { LaunchdialogComponent } from './launchdialog/launchdialog.component';

import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { JsonFormsModule } from '@jsonforms/angular';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { StrudelapplistComponent } from './strudelapplist/strudelapplist.component';
import { KeygenComponent } from './keygen/keygen.component';
import { TransferComponent } from './transfer/transfer.component';
import { Sv2SideNavComponent } from './sv2-side-nav/sv2-side-nav.component';
import { ShareconnectComponent } from './shareconnect/shareconnect.component';
import { LaunchDialogComponent } from './launch-dialog/launch-dialog.component';
import { ModaldialogComponent } from './modaldialog/modaldialog.component'
import {OverlayModule} from '@angular/cdk/overlay';
import { LoginComponent, SafeHtmlPipe } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { LogoutComponent } from './logout/logout.component';
import { AccountinfoComponent } from './accountinfo/accountinfo.component';
import {SettingsService } from './settings.service';
import {NotificationsService} from './notifications.service';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { OurServicesComponent } from './ourservices/ourservices.component';
import { ContactUsComponent } from './contactus/contactus.component';
import { NoaccountComponent } from './noaccount/noaccount.component';
import { WarndialogComponent } from './warndialog/warndialog.component';
import { ExterndialogComponent } from './externdialog/externdialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LaunchformComponent } from './launchform/launchform.component';



// import { FileExplorerModule } from './file-explorer/file-explorer.module';




@NgModule({
    declarations: [
        AppComponent,
        LauncherComponent,
        JoblistComponent,
        JobComponent,
        // SiteselectionComponent,
        // TeserrorsComponent,
        LogoutdialogComponent,
        // LaunchdialogComponent,
        StrudelapplistComponent,
        KeygenComponent,
        TransferComponent,
        Sv2SideNavComponent,
        ShareconnectComponent,
        LaunchDialogComponent,
        ModaldialogComponent,
        LoginComponent,
        SettingsComponent,
        LogoutComponent,
        AccountinfoComponent,
        SafeHtmlPipe,
        AboutUsComponent,
        OurServicesComponent,
        ContactUsComponent,
        NoaccountComponent,
        WarndialogComponent,
        ExterndialogComponent,
        LaunchformComponent,

    ],
    imports: [
        BrowserModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatFormFieldModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        MatListModule,
        MatTableModule,
        MatCardModule,
        MatTabsModule,
        MatToolbarModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSlideToggleModule,
        NoopAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatSidenavModule,
        MatExpansionModule,
        MatIconModule,
        MatSnackBarModule,
        //FileExplorerModule,
        MatProgressBarModule,
        OverlayModule,
        MatMenuModule,
        MatBadgeModule,
        MatDividerModule,
        FlexLayoutModule,
        JsonFormsModule,
        JsonFormsAngularMaterialModule,
    ],
    //providers: [ StrudelappsService, ComputesitesService, TesService, SubmitAppService, MatDialog, AuthorisationService,BackendSelectionService,SettingsService],
    providers: [NotificationsService, ComputesitesService, TesService, BrowserWindowService, SubmitAppService, MatDialog, AuthorisationService, BackendSelectionService, SettingsService, JobsService, IpcService],
    bootstrap: [AppComponent]
})
export class AppModule { }
