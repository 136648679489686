<div class="other-component" fxLayout="row" fxLayoutAlign="space-around">
    <div fxFlex></div>
  <div fxLayout="column" class="content">
     <div class="heading"><h1>Login</h1></div>
      <mat-divider></mat-divider>


        
            <!--<div  fxFlex style="flex: 1 1 0%; box-sizing: border-box">-->
                <div fxLayout="column" fxLayoutAlign="none stretch"  >
                    <div style="height: 50px"></div>
                    <mat-form-field style="width: 100%">
                        <mat-label>Choose a service</mat-label>
                        <mat-select [(value)]="selected">
                            <mat-option *ngFor="let sshauthzserver of loggedOutAuthZ" [value]="sshauthzserver">
                                {{ sshauthzserver.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div style="height: 50px"></div>
                    <div fxLayout="row" fxLayoutAlign="space-around center" style="width: 100%">
                        <button  mat-flat-button (click)="login()" [disabled]="selected === undefined" color=primary style="width: 25%; text-align: center">Login</button>
                    </div>
                    <div style="height: 50px"></div>
                    <div fxFlex *ngIf="selected !== undefined" [innerHTML]="selected.desc | safeHtml"></div>
                </div>
                <!--</div>-->
    </div>
    <div fxFlex></div>
</div>
