import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  public theme$: BehaviorSubject<string>;
  public menuToggle$: BehaviorSubject<boolean>;
  public useMenu$: BehaviorSubject<boolean>;
  public appdesc: boolean;
  public logging: boolean;
  public warndialog: boolean;

  constructor() { 
    this.menuToggle$ = new BehaviorSubject<boolean>(true);
    this.theme$ = new BehaviorSubject<string>(environment.default_theme);
    this.useMenu$ = new BehaviorSubject<boolean>(true);
    this.logging = true;
    this.appdesc = true;
    this.warndialog = true;
    this.getTheme();
    this.getAppDesc();
    this.getWarnDialog();
    this.getLogging();
  }

  getLogging() {
    let storedvalue = JSON.parse(localStorage.getItem('strudel-logging'));
    if (storedvalue !== null) {
      this.logging = storedvalue;
    }
  }

  setLogging(event) {
    this.logging = event.checked;
    localStorage.setItem('strudel-logging',JSON.stringify(this.logging));
  }

  getAppDesc() {
    let storedvalue = JSON.parse(localStorage.getItem('strudel-app-desc'));
    if (storedvalue !== null) {
      this.appdesc = storedvalue;
    }
  }

  setAppDesc(event) {
    this.appdesc = event.checked;
    localStorage.setItem('strudel-app-desc',JSON.stringify(this.appdesc));
  }

  getWarnDialog() {
    let storedvalue = JSON.parse(localStorage.getItem('strudel-warn-dialog'));
    if (storedvalue !== null) {
      this.warndialog = storedvalue;
    }
  }

  setWarnDialog(event) {
    this.warndialog = event.checked;
    localStorage.setItem('strudel-warn-dialog',JSON.stringify(this.warndialog));
  }

  getTheme() {
    var theme: string;
    theme = localStorage.getItem('strudel-theme');
    if (theme == null) {
      this.theme$.next(environment.default_theme);
    } else {
      this.theme$.next(theme);
    }
  }

  setTheme(v: string) {
    this.theme$.next(v);
    localStorage.setItem('strudel-theme',v);
  }
}
