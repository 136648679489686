import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Job } from '../job';
import {TesService} from '../tes.service';
import { timer, Subscription } from 'rxjs';
import { repeat } from 'rxjs/operators';
import { AppAction, Strudelapp} from '../strudelapp';
import {BackendSelectionService} from '../backend-selection.service';
import {IpcService} from '../ipc.service';


@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.css']
})
export class JobComponent implements OnInit, OnDestroy {

  @Input() jobdata: Job;
  @Input() updating: Boolean;
  public available: Boolean;
  public nocancel: Boolean;
  public resources: string;
  public cpu_resources: string;
  public mem_resources: string;
  public timeremaining: string;
  private updatesub: Subscription;
  constructor(private tesService: TesService, public backendService: BackendSelectionService, private ipcService: IpcService,
    ) {



}


  ngOnDestroy() {
    if (this.updating) {
      this.updatesub.unsubscribe();
    }
  }
  ngOnInit() {
    this.updateFields();
    if (this.updating) {
      this.updatesub = timer(1000).pipe(repeat()).subscribe(() => this.updateFields());
    }
  }

  parseDate(date) {
    const parsed = Date.parse(date);
    if (!isNaN(parsed)) {
      return parsed;
    }

      return Date.parse(date.replace(/-/g, '/').replace(/[a-z]+/gi, ' '));
  }

  updateFields() {
    if (this.jobdata.app != null) {
      if (this.jobdata.app.startscript === null) {
        this.nocancel = true;
      } else {
        this.nocancel = false;
      }
    } else {
      this.nocancel = false;
    }
    if (this.jobdata.state == "Finished") {
      this.nocancel = true;
    }
    this.resources="";
    this.cpu_resources = "";
    this.mem_resources = "";
    if (this.jobdata.cpus != undefined) {
        this.cpu_resources = this.cpu_resources+"CPUs: "+this.jobdata.cpus;
    }
    if (this.jobdata.mem != undefined) {
        this.mem_resources = this.mem_resources+"Mem: "+this.jobdata.mem;
    }
    if (this.jobdata.endtime != undefined) {
        let end = this.parseDate(this.jobdata.endtime);
        let remaining = end.valueOf() - Date.now().valueOf();
        this.timeremaining = this.secondsToHms(remaining/1000);
    } else {
      if (this.jobdata.state == "PENDING") {
        if (this.jobdata.starttime != undefined && this.jobdata.starttime != null) {
          this.timeremaining = "Estimated start at "+this.jobdata.starttime;
        } else {
          this.timeremaining = "Job is scheduling";
        }
      }
    }

  }

  secondsToHms(d: number) {
    var h: number = Math.floor(d / 3600);
    var m: number = Math.floor(d % 3600 / 60);
    var s: number = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    return hDisplay + mDisplay ; 
  }

  onCancel() {
    this.tesService.cancel(this.jobdata);
  }

  onConnect(action: AppAction) {
    this.jobdata.connectionState = null;
    this.tesService.connect(this.jobdata,action);
  }

}
