import {Computesite, Health} from './computesite';
import {Job} from './job';
import {BehaviorSubject} from 'rxjs';
import {Strudelapp} from './strudelapp';
// Identities are defined by a username on a computer, but rather than just
// DNS entry, there is extra info in the Computesite
export class Identity {
  username: string;
  site: Computesite;
  authservice: SshAuthzServer;
  keyCerts: KeyCert[];
  systemalerts: BehaviorSubject<Health[]>;
  accountalerts: BehaviorSubject<Health[]>;
  joblist: BehaviorSubject<Job[]>;
  quotas: any[];
  expiry: number;
  appCatalog: BehaviorSubject<Strudelapp[]>;
  appCatalogStatic: Strudelapp[];
  constructor( username: string, site: Computesite, expiry: number) {
    this.username = username;
    this.site = site;
    this.keyCerts = [];
    this.systemalerts = new BehaviorSubject<Health[]>(null);
    this.accountalerts = new BehaviorSubject<Health[]>(null);
    this.joblist = new BehaviorSubject<Job[]>([]);
    if (site !== null) {
      this.appCatalog = new BehaviorSubject<Strudelapp[]>(site.appCatalog);
    } else {
      this.appCatalog = new BehaviorSubject<Strudelapp[]>([]);
    }
    this.quotas = [];
    this.expiry = expiry;
    
  }

  copy_skip_catalog(): Identity {
    let id = new Identity(null,null,null);
    id.username = this.username;
    id.systemalerts = null;
    id.accountalerts = null;
    id.joblist = null;
    id.site = new Computesite();
    id.site.url = this.site.url;
    id.site.host = this.site.host;
    id.site.name = this.site.name;
    id.site.cafingerprint = this.site.cafingerprint;
    id.site.dtn = this.site.dtn;
    id.site.lscmd = this.site.lscmd;
    id.site.dtnport = this.site.dtnport;
    id.quotas = [];
    return id;
  }
  displayName(): string {
    return this.username+'@'+this.site.name;
  }
  repr(): string {
    return JSON.stringify([this.username,this.site.cafingerprint,this.site.host,this.site.url]);
  }
}

export class AuthToken {
  token: string;
  sshauthzservice: SshAuthzServer;
  constructor( token: string, sshauthzservice: SshAuthzServer ) {
    this.token = token;
    this.sshauthzservice = sshauthzservice;
  }
}

export class KeyCert {
  key: string;
  cert: string;
  // authservice: SshAuthzServer;
}

export class SshAuthzServer {
  base: string;
  authorise: string;
  sign: string;
  client_id: string;
  name: string;
  icon: string;
  scope: string;
  logout: string;
  userdefined: boolean;
  cafingerprint: string;
  desc: string;
}
