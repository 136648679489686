<!-- <mat-accordion> -->
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
    More Services
  </mat-panel-title>
  </mat-expansion-panel-header>
  <div *ngFor="let sshauthzserver of (authService.loggedInAuthZ$ | async)" style="width: 100%">
      <button mat-button (click) =logout()>Logout {{ sshauthzserver.name }}</button>
  </div>
  <div *ngFor="let sshauthzserver of (authService.loggedOutAuthZ$ | async)" style="width: 100%">
      <button mat-button (click)=login(sshauthzserver) fxFlex style="text-align: left"> Run on {{ sshauthzserver.name }}</button>
      <button mat-icon-button *ngIf="sshauthzserver.userdefined === true"><mat-icon>remove</mat-icon></button>
  </div>
  <div style="width: 100%">
      <button mat-button (click) ="authService.updateAgentContents()"> Refresh </button>
  </div>
</mat-expansion-panel>
<!-- </mat-accordion> -->
