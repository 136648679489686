import { Injectable, EventEmitter } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of, ReplaySubject, combineLatest } from 'rxjs';
import { fromEvent, throwError, Subscription, merge } from 'rxjs';
import { catchError, map, tap, filter } from 'rxjs/operators';
import { Job } from './job';
import { AppAction, Strudelapp, StrudelappInstance } from './strudelapp';
import { Computesite, Health } from './computesite';
import { Identity, AuthToken, KeyCert, SshAuthzServer } from './identity';
import { BatchInterface } from './batchinterface';
import { repeat, takeUntil, take, switchMap } from 'rxjs/operators';
import { LocationStrategy, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorisationService } from './authorisation.service';
import { BackendSelectionService } from './backend-selection.service';
import {BrowserWindowService} from './browser-window.service';
import { NotificationsService } from './notifications.service';
import { APIServer } from './apiserver';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { IpcService } from './ipc.service';
import { v4 as uuid } from 'uuid';


/** The TES service contains ways to start Tunnels, and Execute programs
Its also responsible for querying a compute site for running jobs */



@Injectable({
  providedIn: 'root',
})
export class TesService {
public Base: string;
private twsproxy: string;
public jobs: any[];
private appwindow: any;
private updateJobSub: Subscription;
private updateUserHealthSub: Subscription;
private cachetincidents: BehaviorSubject<Health[]>;
private cancelRequests$: Subject<boolean>;
public openWindow$: Subject<any>;


  constructor(private http: HttpClient,
              private authorisationService: AuthorisationService,
              private backendSelectionService: BackendSelectionService,
              private location: Location,
              private browserWindow: BrowserWindowService,
              private notifications: NotificationsService,
              private ipcService: IpcService,
  ) {
    this.cancelRequests$ = new Subject<boolean>();
    this.openWindow$ = new Subject<any>();
    this.backendSelectionService.apiserver.subscribe( (value) => { if (value != null) {this.twsproxy = value.tws ; this.Base = value.tes }});
 }



 private buildParams(app: Strudelapp, identity: Identity, batchinterface: BatchInterface, appinst?: any): string {
   let params = new URLSearchParams();
   let id = identity.copy_skip_catalog();
   if (appinst!== null)  {
     params.set('appinstance',JSON.stringify(appinst));
   }
   params.set('app',JSON.stringify(app));
   params.set('interface',JSON.stringify(batchinterface));
   params.set('identity',JSON.stringify(id));
   return params.toString();
 }


 public cancelHealthRequests() {
    this.cancelRequests$.next(true);
 }

 private getUserHealthError(error: any, identity: Identity) {
   identity.accountalerts.next([]);
   if (identity.expiry < Date.now() || (error.hasOwnProperty("status") && error.status == 401)) {
    this.notifications.notify("Your login to "+identity.site.name+ " has expired. Please log in again",   () => { this.authorisationService.refresh$.next(true); });
    
    this.cancelHealthRequests();
    return;
  }
   console.error(error)
   this.notifications.notify("There was an error checking your user account");
 }


getUserHealth(identity: Identity)  {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let params = new URLSearchParams();
  if (identity === undefined || identity === null) {
    return
  }
  if (identity.site.userhealth === undefined) {
    identity.accountalerts.next([]);
    return
  }
  params.set('statcmd',JSON.stringify(identity.site.userhealth));
  params.set('host',JSON.stringify(identity.site.host));
  params.set('username',JSON.stringify(identity.username));
  //this.cancelRequests$.next(false);
  this.updateUserHealthSub = this.runCommand(identity,identity.site.userhealth)
    .pipe(
      takeUntil(this.cancelRequests$),
    )
    .subscribe(resp => this.addUserHealth(identity,resp), error => this.getUserHealthError(error,identity));
}

getUserHealthObservable(identity: Identity)  {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let params = new URLSearchParams();
  if (identity === undefined || identity === null) {
    return
  }
  if (identity.site.userhealth === undefined) {
    identity.accountalerts.next([]);
    return
  }
  params.set('statcmd',JSON.stringify(identity.site.userhealth));
  params.set('host',JSON.stringify(identity.site.host));
  params.set('username',JSON.stringify(identity.username));
  //this.cancelRequests$.next(false);
  return this.runCommand(identity,identity.site.userhealth)
    .pipe(
      takeUntil(this.cancelRequests$),
      catchError((err) => this.networkError(err)),
      catchError((err) => this.authError(err))
    )
}

public networkError(error): Observable<any> {
  if (error.hasOwnProperty('status') && error.status == 0) {
    this.notifications.notify("A network error occurred. Are you connected to the internet?")
    return of([])
  } 
  return throwError(error);
}


public authError(error): Observable<any> {
  if (error.hasOwnProperty('status') && error.status == 401) {
    this.cancelHealthRequests();
    return of([])
  } 
  return throwError(error);
}


public getHealthAlertsObservable(identity: Identity) {
  return combineLatest([this.getUserHealthObservable(identity)])
}

public getCachetIncidentsObservable(identity: Identity) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: false};
  let params = new URLSearchParams();
  if (identity.site.cacheturis === undefined ||  identity.site.cacheturis.length == 0) {
    identity.systemalerts.next([]);
    return
  }
  for (let uri of identity.site.cacheturis) {
    this.http.get(uri,options)
      .pipe(takeUntil(this.cancelRequests$))
      .subscribe(resp => this.addCachetIncidents(identity,resp), error => this.getCachetIncidentsError(error,identity));
  }
}

private addCachetIncidents(identity,resp) {
  let ci = [];
  for (let i of resp.data) {
    if (i.status == 3 || i.status == 4) {
      continue;
    }
    let h = new Health();
    h.stat = 'error';
    h.msg = i.message;
    ci.push(h);
  }
  identity.systemalerts.next(ci);
}

public addUserHealth(identity,resp) {
  let ci = []
  if (resp !== undefined && resp !== null) {
    for (let i of resp) {
      let h = new Health();
      h.stat = i.stat;
      h.msg = i.message;
      if (i.title != undefined) {
        h.title = i.title;
      }
      if (i.type != undefined) {
        h.type = i.type;
        h.data = i.data;
      }
      ci.push(h);
    }
  }
  identity.accountalerts.next(ci);
}

 private getCachetIncidentsError(error: any, identity: Identity) {
   if (error.status == 0) {
     return
   }
   console.error(error);
   if (error.status == 401) {
     console.error('getCacheIncidentsError');
    this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.refresh$.next(true); }  );
     return
   }
   if (error.status == 400) {
       this.notifications.notify(error);
       return
   }
 }


 submissionError(identity: Identity, error: any) {
  if (identity.expiry < Date.now()) {
    console.error('submissionError');
    this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.updateAgentContents().subscribe( () => {return} ) }  );
    return;
  }
   if (error.status != 0) {
       try {
         this.notifications.notify(error);
         console.error(error);
       } catch {
         this.notifications.notify('Job Submission failed');
         console.error(error);
       }
   }
 }

 cancelError(error: any) {
   if (error.status != 0) {
       try {
         this.notifications.notify(error);
         console.error(error);
       } catch {
         this.notifications.notify('Job Canceling failed');
         console.error(error);
       }
   }
 }

 submit(app: Strudelapp, identity: Identity, batchinterface: BatchInterface, ftidentities?: Identity[], appparams?: any) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  this.notifications.notify('Submitting job');
  //let keys = JSON.stringify(this.authorisationService.getKeys());
  let ids = [];
  if (ftidentities != undefined) {
    for (let id of ftidentities) {
      ids.push(id.copy_skip_catalog())
    }
  }

  var o: Observable<any>;
  if (identity.site.submitcmdprefix !== null && identity.site.submitcmdprefix !== undefined) {
    o = this.runCommand(identity, identity.site.submitcmdprefix+batchinterface.submitcmd, {'input': app.startscript})
  } else {
    o = this.runCommand(identity, batchinterface.submitcmd, {'input': app.startscript})
  }
  o.subscribe(resp => { this.notifications.notify(null) },
    error => this.submissionError(identity, error));
 }


 cancel(job: Job) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let cmd = job.identity.site.cancelcmd.replace("{jobid}",job.jobid);

  var o: Observable<any>;
  o = this.runCommand(job.identity, cmd, {});                  
  o.subscribe(
    () => { this.notifications.notify(null) },
    error => this.cancelError(error)
  );
 }

 public getAppInstance(job: Job, action: AppAction, apiserver: APIServer) {


  //-    'host': job.batch_host,
  //-    'bastion': job.identity.site.host,
  

   let cmd = action.paramscmd.replace("{jobid}",job.jobid);
   return this.runCommand(job.identity, cmd, {'bastion': job.identity.site.host, 'host': job.batch_host});
 }

 public createTunnel(job: Job, appinst: any, action: AppAction, apiserver: APIServer): Observable<string> {
   let username = job.identity.username;
   let loginhost = job.identity.site.host;
   let batchhost = job.batch_host;
   let params = new URLSearchParams;
   let headers = new HttpHeaders();
   job.connectionState = 2;

   if ((action.notunnel != undefined) && (action.notunnel == true)) {
     return of('')
   }
   let options = { headers: headers, withCredentials: true};
   let paramstr = params.toString();
   if (this.ipcService.useIpc) {
     let body = {
       'user': username,
       'host': batchhost,
       'bastion': loginhost,
       'appinst': appinst
     }
     return this.ipcService.createTunnel(body)
   } else {
    return this.http.post<string>(apiserver.tes+'/createtunnel/'+username+'/'+loginhost+'/'+batchhost+'?'+paramstr, appinst, options)
   }
 }

 public getAppUrlLocal(job: Job, appinst: any, action: AppAction): Observable<any>{
/*    if (action.client.cmd !== undefined && action.client.cmd !== null) {
    var cmd: string = action.client.cmd[0];
    //return this.ipcService.launch(cmd)
    return this.ipcService.launch(job, appinst, action)
   } */
   console.log('in app urlLocal',appinst);
   let url="http://localhost:"+appinst['localport']+"/"+action.client.redir;
   for (let key in appinst) {
     if (appinst.hasOwnProperty(key)) {
      let value = appinst[key]
      url = url.replace("{"+key+"}",value)
     }
   }
   console.log('appUrlLocal will be',url);
   return of(url);
 }

 public getAppUrl(job: Job, appinst: any, action: AppAction, apiserver: APIServer): Observable<any> {
   let params = new URLSearchParams;
   let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   console.log('in getAppUrl');
   if (this.ipcService.useIpc) {
     console.log('try local');
     return this.getAppUrlLocal(job, appinst, action).pipe(
       tap((v)=>console.log('try to open url',v))
     )
   }
   let pseudoapp = {'client':{'redir':action.client.redir,'cmd':action.client.cmd}};
   params.set('app',JSON.stringify(pseudoapp));
   params.set('appinst',JSON.stringify(appinst));
   let paramstr = params.toString();
   job.connectionState = 3;
   if (action.client.cmd !== undefined && action.client.cmd !== null) {
      return this.http.get<string>(apiserver.tes+'/applaunch?'+paramstr,options)
   }
   console.log('getting appurl from the api server');
   return this.http.get<string>(apiserver.tes+'/appurl?'+paramstr,options)
 }

 public contactUs(form: any) {
   let headers = new HttpHeaders();
   let options = { headers: headers, withCredentials: true};
   return this.backendSelectionService.apiserver.pipe(
     filter((v) => v !== undefined && v !== null),
     switchMap((apiserver) => this.http.post(apiserver.tes+'/contactus',{'data':form},options))
   )
 }

//  public connect(job: Job, action: AppAction, appinst?: any) {
//    let headers = new HttpHeaders();
//    let options = { headers: headers, withCredentials: true};

//    job.connectionState=1
//    // Can no connect till we know which api server we are using. Threfore the apiserver
//    // is the start of the pipe
//    // the API server is behaviorsubject so it should always fire immediately
//    this.backendSelectionService.apiserver.pipe(
//      filter((v) => v !== undefined && v !== null),
//      switchMap((apiserver) => this.getAppInstance(job,action,apiserver),
//        (apiserver,appinst,i1,i2) => {return [apiserver,appinst]}),
//      tap((_) => job.connectionState=2),
//      switchMap(([apiserver,appinst]) => { return this.createTunnel(job,appinst,action,<APIServer>apiserver)},
//        (data,tunnelresp) => { data[1]['localport'] = (<any> tunnelresp)['localport'] ; return data}), // we don't care about data from the tunnel as long as it was successful.`
//      tap((_) => job.connectionState=3),
//      switchMap(([apiserver,appinst]) => this.getAppUrl(job,appinst,action,<APIServer>apiserver),
//        ([apiserver,appinst],url) => {return [apiserver,appinst,url]}),
//      tap((_) => job.connectionState=4),
//    ).subscribe(([apiserver,appinst,url]) => { if (url !== null) {
//                                           this.openWindow$.next( {'job':job, 
//                                                 'url': <string> url, 
//                                                 'usebasicauth':action.client.usebasicauth,
//                                                 'apiserver':apiserver,
//                                             'action':action,'appinst':appinst});
//                                       }
//                                     job.connectionState=null},
//      (err) => { job.connectionState = 0; this.handleError(job.identity, err)})
//  }


 public connect(job: Job, action: AppAction, appinst?: any) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};

  job.connectionState=1
  // Can no connect till we know which api server we are using. Threfore the apiserver
  // is the start of the pipe
  // the API server is behaviorsubject so it should always fire immediately
  this.backendSelectionService.apiserver.pipe(
    filter((v) => v !== undefined && v !== null),
    switchMap((apiserver): Observable<[APIServer, string]> => 
            { return this.getAppInstance(job,action,apiserver)
              .pipe(
                catchError(err => this.handleAppInstanceError(job,action,err)),
                map((v)=>[apiserver,v]))
            } ),
    tap((_) => job.connectionState=2),
    switchMap(([apiserver,appinst]) => 
            { return this.createTunnel(job,appinst,action,<APIServer>apiserver)
              .pipe(
                tap((v)=> { console.log('created tunnel got data',v)}),
                catchError(err => this.handleTunnelError(job,action,err)),
                map((v) => 
                { 
                  appinst['localport'] = v['localport']; 
                  return [apiserver, appinst];
                }))
            } ),
    tap((_) => job.connectionState=3),
    switchMap(([apiserver,appinst]) => 
            { return this.getAppUrl(job,appinst,action,<APIServer>apiserver)
              .pipe(map((url) => [apiserver, appinst, url]))
            }),
    tap((_) => job.connectionState=4),
  ).subscribe(([apiserver,appinst,url]) => { if (url !== null) {
                                         this.openWindow$.next( {'job':job, 
                                               'url': <string> url, 
                                               'usebasicauth':action.client.usebasicauth,
                                               'apiserver':apiserver,
                                           'action':action,'appinst':appinst});
                                     }
                                   job.connectionState=null},
    (err) => { job.connectionState = 0; this.handleError(job.identity, err)})
}

  public runCommand(identity: Identity, command: string, optional?: any): Observable<any> {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  if (identity === undefined || identity === null) {
    return
  }

  let body = {
    'user': identity.username, 
    'host': identity.site.host,
    'cmd': command,
  }
  if (optional !== undefined && optional !== null) {
    body = { ...body, ...optional };
  }

  var o: Observable<any>;
  if (this.ipcService.useIpc) {
    body['tag'] = uuid();
    console.log('Im using tag',body['tag']);
    o = this.ipcService.runRemoteCommand(body)
  } else {
    o = this.http.post<any>(this.Base+'/remotecommand', body, options)
  }

  return o;




}


public postMkDir(id: Identity, path: string, name: string ) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let params = new URLSearchParams;
  params.set('identity',JSON.stringify(id));
  params.set('path',JSON.stringify(path));

  return this.http.post<any>(this.Base+'/mkdir?'+params.toString(),name, options);
}

public getSftpData(id: Identity, path: string, cd: string ) {
  let headers = new HttpHeaders();
  let options = { headers: headers, withCredentials: true};
  let params = new URLSearchParams;
  params.set('identity',JSON.stringify(id));
  params.set('path',JSON.stringify(path));
  params.set('cd',JSON.stringify(cd));

  return this.http.get<any>(this.Base+'/ls?'+params.toString(),options)
                      // .pipe(catchError(this.handleError))

}

private handleError(identity: Identity, error: any) {
  console.log('in handelError');
  console.error(error);
  // if (identity.expiry < Date.now()) {
  //   this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.updateAgentContents().subscribe( () => {return} ) }  );
  //   return;
  // }
  // if (error.error instanceof ErrorEvent) {
  //   this.notifications.notify("A networking error occured.")
  //   return
  // } 
  // if (error.hasOwnProperty("status") && error.status == 500) {
  //   this.notifications.notify(error);
  //   return
  // }
  // if (error.hasOwnProperty("status") && error.status == 400) {
  //   this.notifications.notify(error);
  //   return
  // }
  // this.notifications.notify(error);
}

private handleAppInstanceError(job: Job, action: AppAction, error: HttpErrorResponse): Observable<any> {
  console.error(error);
  console.log('in handleAppInstanceError');
  const msg: string = "The command " + action.paramscmd + " on host " + job.batch_host + " "
  if (job.identity.expiry > Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    this.notifications.notify("It looks like the application is running slow. Please try again in a few minutes.");
    return throwError("app instance error, possibly pam_slurm_adopt");
  } else {
    console.log('else strategy',job.identity.expiry, Date.now(),error.status);
    return this.handleGenericError(job,action,error,msg);
  }
}

private handleTunnelError(job: Job, action: AppAction, error: HttpErrorResponse): Observable<any> {
  console.error(error);
  console.log('in handle tunnel error');
  const msg: string = "Attempting to create an SSH tunnel to" + job.batch_host + " via " + job.identity.site.host;
  if (job.identity.expiry > Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    this.notifications.notify("It looks like the application is running slow. Please try again in a few minutes.")
    return throwError("tunnel error, possible pam_slurm_adopt");
  } else {
    return this.handleGenericError(job,action,error,msg);
  }
}

private handleGenericError(job: Job, action: AppAction, error: HttpErrorResponse, msg: string): Observable<any> {
  var emsg: string = "";
  if (error.hasOwnProperty('error') && error.error.hasOwnProperty('message') ) {
    emsg = error.error.message;
  } else {
    emsg = error.message;
  }
  if (job.identity.expiry < Date.now() && (error.hasOwnProperty("status") && error.status == 401)) {
    console.error('handleGenericError');
    this.notifications.notify("Your login has expired. Please log in again",   () => { this.authorisationService.refresh$.next(true); }  );
    return throwError(error);
  }
  if (job.identity.expiry > Date.now() && error.hasOwnProperty("status") && error.status == 401) {
    this.authorisationService.reAdd$.next(job.identity);
    this.notifications.notify("Your login expired. Logging you back in again automatically. Please rety what you were doing");
  }

  if (error.hasOwnProperty("status") && error.status == 500) {
    this.notifications.notify("The Strudel2 API server had an error.\n Please report this via the contact us link.\nThe error message was"+emsg);
    return throwError(error);
  }
  if (error.hasOwnProperty("status") && error.status == 400) {
    this.notifications.notify(msg + " unexpectedly returned an error message.\n Please report this via the contact us link.\nThe error message was\n" + emsg);
    return throwError(error);
  }
  if (error.hasOwnProperty("status") && error.status == 504) {
    this.notifications.notify(msg + " timed out. Is the application server OK?");
    return throwError(error);
  }
  if (error.error instanceof ErrorEvent) {
    this.notifications.notify("A networking error occured. Is your internet connection OK?")
    return throwError(error);
  } 
  this.notifications.notify(error);
  return throwError(error);
}



}
