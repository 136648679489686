import { Injectable } from '@angular/core';
import { APIServer } from './apiserver';
import {BehaviorSubject, pipe, Observable, combineLatest, of, timer} from 'rxjs';
import {map, tap, switchMap, timeout, catchError, repeat } from 'rxjs/operators';

import { HttpClientModule, HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BackendSelectionService {

  public apiservers: BehaviorSubject<APIServer[]>;
  public apiserver: BehaviorSubject<APIServer>;
  public localapi: BehaviorSubject<boolean>;

    constructor(private http: HttpClient) { 
        this.apiservers = new BehaviorSubject([]);
        this.apiserver = new BehaviorSubject(null);
        this.localapi = new BehaviorSubject(false);
        this.apiservers.subscribe((v) => this.updateApiServer(v));
        //this.apiservers.subscribe((v) => this.pingApiServers(v));
        //this.pingApiServers();
        this.initApiServers();
        this.apiserver.subscribe((v) => console.log('apiserver change',v));
        this.apiservers.subscribe((v) => console.log('apiservers change',v));
        timer(10000).pipe(repeat()).subscribe(() => this.pingApiServers(10000))
        //this.apiservers.subscribe((v) => removeFailedServer(v):
    }

  private serverAvailable(s: APIServer) {
    if (s.tes.indexOf('localhost') == -1) {
      return true;
    } else {
      if (s.ping === null ) {
        return false;
      } else {
        return true;
      }
    }
  }
  private updateApiServer(apiservers: APIServer[]) {
    var nextserver: APIServer;
    //TODO
    // If the current API server is unavailable in the list of apiservers, move to a new one
    // also test the ping response for the APIservers to select the best one
    
    if (this.apiserver.value === null || this.apiserver.value === undefined || !(this.serverAvailable(this.apiserver.value))) {
      var lastserver: APIServer;
      try {
          
          lastserver = <APIServer>JSON.parse(localStorage.getItem('apiserver'));
      } catch {
          lastserver = null;
      }
      if (lastserver !== null) {
        var s: APIServer;
        for ( s of apiservers) {
          if (lastserver.tes.indexOf(s.tes) != -1 && this.serverAvailable(s)) {
            nextserver = s;
          }
        }
      }
      if (nextserver === undefined) {
        nextserver = apiservers[0]
      }

      if (nextserver !== undefined && nextserver !== null && nextserver.tes.indexOf('localhost') != -1) {
        this.localapi.next(true);
      } else {
        this.localapi.next(false);
      }
      this.apiserver.next(nextserver);
    }
  }



  private pingApiServers(timeouts: number) {
    let headers = new HttpHeaders();
    let options = { headers: headers, withCredentials: false};
    var servers: APIServer[];
    servers = this.apiservers.value;
    for (let server of servers) {
      combineLatest([of(performance.now()),
      this.http.get<string>(server.tes+'/ping',options).pipe(
        timeout(timeouts))])
      .subscribe((ok) => {server.ping = (performance.now() - <number>(ok[0]))},
                 (err) => {server.ping = null});
    }
  }

/*    this.testApiServers(this.apiservers.value).pipe(
      map(function (v) {return v.server})
    )
      .subscribe((v) => this.apiservers.next(v))}*/

  private testApiServers(servers: APIServer[]): Observable<any> {
    var obs: Observable<any>[] = [];
    let headers = new HttpHeaders();
    let options = { headers: headers, withCredentials: false};
    for (let server of servers) {
      //obs.push( this.http.get(s.tes+'/sshagent',options).pipe(map((r) => {return {'server':s,'check':r}})));
      obs.push( this.http.get<string>(server.tes+'/ping',options).pipe(
        timeout(10000),
        catchError(e=> {console.error('testing api server',server); console.error(e); return of(null)}),
        map((v) => {return {'server':server,'query':v}}),
        ));
    }
    return combineLatest(obs);
  }
 
    private testApiServer(server: APIServer) {
      this.http.get(server.tes+'/ping').subscribe((ok) => {return true}, (err) => {return false})
    }

    private initApiServer() {
      var lastserver: APIServer;
      try {
          
          lastserver = <APIServer>JSON.parse(localStorage.getItem('apiserver'));
      } catch {
          lastserver = null;
      }
      if (this.apiserver.value === null) {
          this.apiservers.subscribe((v) => this.defaultApiServer(v))
      }
      if (this.apiserver.value !== undefined && this.apiserver.value !== null && this.apiserver.value.tes.indexOf('localhost') != -1) {
        this.localapi.next(true);
      } else {
        this.localapi.next(false);
      }
    }

    private defaultApiServer(v: APIServer[]) {
      this.apiserver.next(v[0]);
    }

    private saveLastApiServer(s: APIServer) {
        localStorage.setItem('apiserver', JSON.stringify(s));
    }

    public setApiServer(server: APIServer) {
      this.apiserver.next(server);
    if (this.apiserver.value.tes.indexOf('localhost') != -1) {
      this.localapi.next(true);
    } else {
      this.localapi.next(false);
    }
      this.saveLastApiServer(this.apiserver.value)
    }
    

    /*     storeLocalAPIServers(apiservers) {
         localStorage.setItem('localAPIServers',JSON.stringify(apiservers));
         this.getAPIServers();
     }
     
     removeLocalAPIServer() {
         localStorage.removeItem('localAPIServers');
         this.getAPIServers();
     }*/

     private initApiServers() {
       let headers = new HttpHeaders();
       let options = { headers: headers, withCredentials: false};
       this.http.get<APIServer[]>('./assets/config/apiservers.json',options).subscribe(resp => this.updateAPIServers(resp));
     }


     private getAPIServers() {
       let headers = new HttpHeaders();
       let options = { headers: headers, withCredentials: false};
       this.http.get<APIServer[]>('./assets/config/apiservers.json',options).pipe(
         switchMap((v) => this.testApiServers(v)),
         map((list, idx) => (<any[]>list)
                              .filter(function (v) { return v.query != null})
                              .map(function (v) {return v.server})) // Note the two maps are not the same one is an rxjs observable map one is a list map
       ).subscribe(resp => this.updateAPIServers(resp));
     }

     private updateAPIServers(resp) {
         var s: APIServer;
         var list: APIServer[] = []
         var current: APIServer;
         var found: boolean; 
         var localServers: APIServer[] = [];
         current = this.apiserver.value;
         found = false;
         try {
             localServers = <APIServer[]>JSON.parse(localStorage.getItem('localAPIServers'));
         } catch {
             localServers = []
         }

         for (s of <APIServer[]>resp) {
             if (current !== undefined && current !== null && s.name == current.name) {
                 list.push(current);
                 found = true;
             } else {
                 list.push(s);
             }
         }
         if (localServers !== null) {
             for (s of localServers) {
                 if (current !== null && s.name == current.name) {
                     list.push(current);
                     found = true;
                 } else {
                     list.push(s);
                 }
             } 
         }
         if (!found && current !== undefined) {
             list.push(current);
         }
         this.apiservers.next(list);
     }

}
