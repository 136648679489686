import { Component, OnInit, NgModule, Inject, Input } from '@angular/core';
import { MatDialog, } from '@angular/material/dialog';
import { Observable, Subject, BehaviorSubject, interval, from, combineLatest, merge, Subscription } from 'rxjs';
import { takeUntil, switchMap, filter,  tap,  startWith,  shareReplay, withLatestFrom} from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Strudelapp } from '../strudelapp';
import { Identity } from '../identity';
import { Health } from '../computesite';

import { TesService } from '../tes.service';
import { AuthorisationService } from '../authorisation.service';
import { ComputesitesService } from '../computesites.service';
import { SettingsService} from '../settings.service';
import { JobsService} from '../jobs.service';


@Component({
  selector: 'app-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.css']
})
export class LauncherComponent implements OnInit {
  public strudelapps: Strudelapp[];

  public app$: BehaviorSubject<Strudelapp>;
  public identity : Identity;
  public appidentities: Identity[];

  private destroy$: Subject<boolean>;
  private sub: Subscription;

  constructor( public dialog: MatDialog,
                public tesService: TesService,
                public authService: AuthorisationService,
                public computeSitesService: ComputesitesService,
                private router: Router,
                private route: ActivatedRoute,
                public settingsService: SettingsService,
                private jobsService: JobsService,
                ) {

    this.app$ = new BehaviorSubject<Strudelapp>(null);
    this.destroy$ = new Subject<boolean>();
  }

  countErrors(a: Health[], b: Health[]) {
    var count: number = 0
    var h: Health;
    if (a != null) {
      for (h of a) {
        if (h.stat == 'error' || h.stat == 'warn') {
          count++;
        }
        if(!isNaN(parseInt(h.stat))) {
          count = count + parseInt(h.stat);
        }
      }
    }
    if (b != null) {
      for (h of b) {
        if (h.stat == 'error' || h.stat == 'warn') {
          count++;
        }
        if(!isNaN(parseInt(h.stat))) {
          count = count + parseInt(h.stat);
        }
      }
    }
    return count;
  } 

  ngOnInit() {
    this.pipelines();
    this.sub = this.authService.refresh$.subscribe( () => {this.destroy$.next(true); this.pipelines()})
  }

  pipelines() {

    // This subscribes to the computeSiteService which provides a list of computers and ids, and 
    // retrives the catalog of applications each identity is able to run
    // The result will be shared to
    // a. update the list of appidenties shown in the component
    // b. Examine the path and make sure that the application and ID in the URL are valid
    // c. Start the periodic queries to update the user info for each user@site
    const appIdCatalog$ = this.computeSitesService.appidentities$.pipe(shareReplay(3));
    // this will store a copy of appidenties in the component so we can render it
    appIdCatalog$.subscribe((v) => this.appidentities = v);

    // this will examine the current path and navigate if its invalid, or store the app/id if its OK
    // every time the route changes it will also kick of polling for the jobs associated with that identitiy. 
    combineLatest([this.route.paramMap, appIdCatalog$])
    .subscribe((v) => this.updateIdApp(v[0],v[1]));

    // this will kick of periodic polling to get the user info
    appIdCatalog$    
    .pipe(
      switchMap((v) => from(v)),
    )
    .subscribe(
      (id) => {
        interval(60000).pipe(
          startWith(0),
          takeUntil(this.destroy$),
          switchMap((v) => this.tesService.getHealthAlertsObservable((<Identity>id)))
        ).subscribe((v) => { this.tesService.addUserHealth(id,v[0])})
      }
    )
  }

  updateIdApp(params: ParamMap, appidentities: Identity[]) {
    
    var idsite: string = params.get('site');
    var apps: string = params.get('app');
    console.log('in updateIdApp',idsite,apps);

    if (appidentities.length == 0) {
      this.router.navigate(['/login']);
      return;
    }
    // The URL doesn't include a compute site
    if (idsite === null) {
        this.router.navigate(['/launch',appidentities[0].displayName(),'accountinfo']);
        this.identity = appidentities[0];
        this.jobsService.setId(this.identity);
        this.app$.next(null);
        return
    }
    // The URL doesn't include an application, navigate
    if (idsite !== undefined && idsite !== null && apps === null) {
      var id: Identity = this.getId(idsite,appidentities);
      if (id == null) {
        this.router.navigate(['/login']);
        return
      }
      this.router.navigate(['/launch',idsite,'accountinfo']);
      this.identity = id;
      this.jobsService.setId(this.identity);

      this.app$.next(null);
      return
    }
    // The URL doesn't match any known compute site. We possibly need to login
    var id: Identity = this.getId(idsite,appidentities);
    if (id === null) {
        this.router.navigate(['/login']);
        return
    }

    var app: Strudelapp = this.getApp(id,apps);
    if (id !== this.identity) {
      this.identity = id;
      this.jobsService.setId(this.identity);
    }     
    if (app !== this.app$.value) {
      this.app$.next(app);
    }
  }

  getApp(id: Identity,v: string): Strudelapp {
    if (v == 'accountinfo') {
      return null;
    }
    return Strudelapp.getApp(v,id.appCatalogStatic);
  }

  getId(v: any, appidentities: Identity[]) {
    if (v === null) {
      console.error('getId called with null value');
      return null;
    }
    if (appidentities.length == 0) {
      console.error('no appidentities yet? call back latter');
      return null
    }

    for ( let id of appidentities) {
      if (v == id.displayName()) {
        return id
      } 
    }

    for ( let id of appidentities) {
      if (v == id.site.name) {
        return id
      }
    }
    return null;
  }


  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.jobsService.cancelJobList();
    if (this.sub !== undefined && this.sub !== null) {
      this.sub.unsubscribe();
    }
  }

}
