import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FileElement } from '../file-explorer/model/element';
import { TesService } from '../tes.service';
import { ComputesitesService } from '../computesites.service';
import { AuthorisationService } from '../authorisation.service';
import { Computesite } from '../computesite';
import { Identity } from '../identity';
import { Router } from '@angular/router';
import {NotificationsService } from '../notifications.service';


export class Transfer {
  srcpath: string;
  dstpath: string;
  name: string;
  direction: number;
  constructor(srcpath: string, dstpath: string, name: string, direction: number) {
    this.srcpath = srcpath;
    this.dstpath = dstpath;
    this.name = name;
    this.direction = direction;
  }
}



@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {

  fileElements: BehaviorSubject<FileElement[]>[];
  pathSubject: BehaviorSubject<string>[];
  path: string[];
  working: BehaviorSubject<Boolean>[];
  canNavigateUp: BehaviorSubject<Boolean>[];
  id: Identity[];
  site: Computesite[];
  disableSend: Boolean;
  redirecturi: string;
  appevent: any;
  transferlist: Transfer[];


  constructor(public computesitesService: ComputesitesService,
              private tesService: TesService,
              private authService: AuthorisationService,
              private router: Router,
              private notifications: NotificationsService) {
    this.path = ['',''];
    this.fileElements = [new BehaviorSubject([]), new BehaviorSubject([])];
    this.working = [ new BehaviorSubject<Boolean>(false), new BehaviorSubject<Boolean>(false)];
    this.canNavigateUp = [ new BehaviorSubject<Boolean>(false), new BehaviorSubject<Boolean>(false)];
    this.pathSubject = [ new BehaviorSubject(""),new BehaviorSubject("")];
    this.id = [null,null];
    this.disableSend = true;
    this.transferlist = [];
  }

  ngOnInit() {
    var fel: FileElement[] = []
    // this.fileElements = [new BehaviorSubject([]), new BehaviorSubject([])];
    // this.working = [ new BehaviorSubject<Boolean>(false), new BehaviorSubject<Boolean>(false)];
    // this.canNavigateUp = [ new BehaviorSubject<Boolean>(false), new BehaviorSubject<Boolean>(false)];
    // this.pathSubject = [ new BehaviorSubject(""),new BehaviorSubject("")];
    // this.id = [null,null];
    // this.disableSend = true;
    setTimeout( () => this.authService.updateAgentContents() )
  }

compareObjects(o1: any, o2: any) {
  if(o1.name == o2.name) {
    return true;
  } else {
    return false;
  }
}

processError(idx,error) {
  this.working[idx].next(false);
  this.notifications.notify(error.error.message);

}

newDirectory(idx: number, name: string) {
  this.working[idx].next(true);
  this.tesService.postMkDir(this.id[idx],this.path[idx],name).subscribe(resp => this.getSftpls(idx,'.'), error => this.processError(idx,error));
}

getSftpls(idx,cd) {
  this.working[idx].next(true);
  this.tesService.getSftpData(this.id[idx],this.path[idx],cd).subscribe(resp => this.updateSftpls(idx,resp), error => this.processError(idx,error));
}

makeFileElements(data) {
  var rv: FileElement[] = [];
  var fe: FileElement;
  for (let f of data) {
    fe = new FileElement();
    if (f.isdir !== undefined) {
      fe.isFolder = f.isdir;
    } else {
      if (f.mode.indexOf('d')== 0) {
        fe.isFolder=true;
      }
    }
    fe.name = f.name;
    fe.size = f.size;
    fe.online = f.online;
    rv.push(fe);
  }
  return rv;
}

 updateSftpls(idx,resp) {
   let files = this.makeFileElements(resp.files);
   if (resp.pwd.length > 1) {
     this.canNavigateUp[idx].next(true);
   } else {
     this.canNavigateUp[idx].next(false);

   }
   this.path[idx] = resp.pwd;
   this.pathSubject[idx].next(this.path[idx]);
   this.fileElements[idx].next(files);
   this.working[idx].next(false);

 }


  setId(idx,event) {
    this.id[idx] = event.value;
    if ((this.id[0] != null) && (this.id[1] != null)) {
      this.disableSend = false;
    }
    this.path[idx]=".";
    this.pathSubject[idx].next(this.id[idx].username+"@"+this.id[idx].site.name+":"+this.path[idx]);
    this.getSftpls(idx,'.');

  }

  navLaunch() {
    this.router.navigate(['/launch']);
  }

  navigateToFolder(idx,element: FileElement) {
    this.getSftpls(idx,element.name);
  }

  navigateUp(idx: number, event: any) {
    this.getSftpls(idx,'..');
  }


  queueFile(idx: number, element: FileElement) {
    let src = idx;
    let dest = (idx+1) % 2;
    this.transferlist.push(new Transfer(this.path[0],this.path[1],element.name,idx));
  }

  postParams() {
    let appparams = JSON.stringify(
      {'sites':[this.id[0],this.id[1]],
        'files': this.transferlist })
    window.parent.postMessage({'appData':appparams},'*');
  }

}
